import Layout from "@/components/layout";

export default function Custom404() {
  return (
    <div className="error-section">
      <h1>
        Welcome to <span style={{ color: "#002A0F" }}>ZENKIFY</span>
      </h1>

      <p>Sorry , The page you are looking for can&apos;t be found</p>

      <p>Try checking your URL</p>

      <h2>

        
        <span style={{ color: "red" }}>404 Error</span>



      </h2>
    </div>
  );
}

Custom404.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};
